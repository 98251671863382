import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from '../molecules/BasicTable';
import orderBy from 'lodash/orderBy';
import Checkbox from '../atoms/Checkbox';
import Box from '@mui/material/Box';
import { Button, SingleLineText, TypeInput } from '@bloomays-lib/ui.shared';
import { ValidationTextField } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { SelectField } from '../atoms/SelectField';
import { ActivityDayDetails } from '@bloomays-lib/types.shared';
import { useEffect, useMemo, useState } from 'react';
import { generateOptionsCRA } from '../../helpers/CRA';
import debounce from 'lodash/debounce';
type FormCRAProps = {
  eachDays: ActivityDayDetails[];
  onCRAUpdate: (activityDayDetailss: ActivityDayDetails[]) => void;
  hoursPerDay: number;
  readOnly?: boolean;
};

export const FormCRA = ({ eachDays, onCRAUpdate, hoursPerDay, readOnly = false }: FormCRAProps) => {
  const [showAstreinteSelect, setShowAstreinteSelect] = useState<{ [key: number]: boolean }>({});
  const [showApplyValue, setShowApplyValue] = useState(false);
  const [valueOfAll, setValueOfAll] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const getShowValueFromEachDays = (days: ActivityDayDetails[]) => {
    const initValueShowAstreinte: { [key: number]: boolean } = {};
    days.forEach((td) => {
      if (td.astreinte && td.astreinte > 0) {
        initValueShowAstreinte[td.id] = true;
      }
    });
    return initValueShowAstreinte;
  };

  const hour = useMemo(() => {
    const hours = generateOptionsCRA(hoursPerDay);
    return hours.map((h) => {
      if (h.value === 1) {
        h.label = '1 journée';
      }
      if (h.value === 0.5) {
        h.label = '1/2 journée';
      }
      return h;
    });
  }, [hoursPerDay]);

  const hourHNO = useMemo(() => {
    return generateOptionsCRA(hoursPerDay, 12);
  }, [hoursPerDay]);

  const SelectAll = () => {
    return (
      <>
        <Checkbox
          id="selectAll"
          checked={isSelectAll}
          name={'selectAll'}
          onChange={(e) => {
            setShowApplyValue(e.target.checked);
            setIsSelectAll(e.target.checked);
            selectAll(e.target.checked);
          }}
        />
        <SingleLineText text={'Tout selectionner'} />
        <SingleLineText text={'(hors week-ends et jours fériés)'} />
      </>
    );
  };

  useEffect(() => {
    const result = getShowValueFromEachDays(eachDays);
    setShowAstreinteSelect(result);
  }, [eachDays]);

  const handleHalfDay = (id: number, nb: number) => {
    const updatedTableData = eachDays.map((item) => {
      if (item.id === id) {
        return { ...item, dayWorked: nb };
      }
      return item;
    });
    onCRAUpdate(updatedTableData);
  };

  const handleShowAstreinte = (id: number, show: boolean, astreinte?: number) => {
    setShowAstreinteSelect((prev) => {
      return { ...prev, [id]: show };
    });
  };

  const handleAstreinte = (id: number, astreinte: number) => {
    const updatedTableData = eachDays.map((item) => {
      if (item.id === id) {
        return { ...item, astreinte: astreinte };
      }
      return item;
    });
    onCRAUpdate(updatedTableData);
  };

  const selectAll = (isSelect: boolean) => {
    const updatedTableData = eachDays.map((item) => {
      const isWeekEndOrHoliday = item.day === 'Samedi' || item.day === 'Dimanche' || item.isHoliday;
      return { ...item, checked: isWeekEndOrHoliday ? false : isSelect };
    });
    onCRAUpdate(updatedTableData);
  };

  const handleWorkedDay = (id: number, checked: boolean) => {
    const updatedTableData = eachDays.map((item) => {
      if (item.id === id) {
        return { ...item, checked: checked };
      }
      return item;
    });
    onCRAUpdate(updatedTableData);
  };

  const handleComment = (id: number, comment: string) => {
    const updatedTableData = eachDays.map((item) => {
      if (item.id === id) {
        return { ...item, comment: comment };
      }
      return item;
    });
    onCRAUpdate(updatedTableData);
  };

  const applyValue = (value: number) => {
    const updatedTableData = eachDays.map((item) => {
      if (item.checked) {
        return { ...item, dayWorked: value };
      }
      return item;
    });
    onCRAUpdate(updatedTableData);
  };

  const debouncedUpdateCra = debounce(handleComment, 200);

  return (
    <>
      {showApplyValue && (
        <ContainerApplyValue>
          <SelectField
            name={'selectAll'}
            value={valueOfAll}
            defaultOption={true}
            options={hour}
            handlechange={(e: any) => {
              setValueOfAll(Number(e.target.value));
            }}
            required={false}
            label={'Valeur à appliquer à la séléction'}
          />
          <Button
            sx={{ marginTop: '6px', marginLeft: '16px' }}
            id="valueOfAllButton"
            textButton="Valider"
            onClick={() => applyValue(valueOfAll === undefined ? 0 : valueOfAll)}
          />
        </ContainerApplyValue>
      )}
      <StyledTable>
        <BasicTable
          columns={[
            <SelectAll key="selectAll" />,
            'Jour',
            'Date',
            'Temps de travail',
            'Heures supp (HNO)',
            'Commentaire',
          ].filter((item, index) => (readOnly ? index !== 0 : true))}
        >
          {orderBy(eachDays, ['id'], ['asc'])?.map((row, i) => (
            <TableRow key={`eachDay-${i}`}>
              {!readOnly && (
                <TableCellBody id={`dayWorkedCheckBox-${i}`}>
                  <Checkbox
                    id={`dayWorkedCheckBoxInput-${i}`}
                    name={`dayWorkedCheckBox${i}`}
                    checked={row.checked}
                    onChange={(e, check) => {
                      setShowApplyValue(true);
                      handleWorkedDay(row.id, check);
                    }}
                    disabled={false}
                    label={''}
                  />
                </TableCellBody>
              )}
              <TableCellBody id={`day-${i}`}>{row.day}</TableCellBody>
              <TableCellBody id={`holiday-${i}`}>
                {row.isHoliday ? (
                  <SingleLineText
                    sx={(theme) => ({
                      color: theme.palette.tertiary.red,
                      fontWeight: 'bold',
                    })}
                    text={`${row.date} Férié !!`}
                  />
                ) : (
                  <SingleLineText text={row.date} />
                )}
              </TableCellBody>
              <TableCell id={`dayWorked${i}`}>
                <SelectField
                  readOnly={readOnly}
                  disabled={readOnly}
                  name={`dayWorkedField${i}`}
                  id={`dayWorkedField${i}`}
                  value={row.dayWorked}
                  options={hour}
                  handlechange={(e: any) => {
                    handleHalfDay(row.id, Number(e.target.value));
                  }}
                  label={''}
                  required={false}
                />
              </TableCell>
              <TableCellBody id={`dayAstreinteCheckBox-${i}`}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {!readOnly && (
                    <Checkbox
                      name={`dayAstreinteCheckBoxInput${i}`}
                      checked={showAstreinteSelect[row.id] === true}
                      onChange={(e, check) => {
                        handleShowAstreinte(row.id, check);
                      }}
                      id={`dayAstreinteCheckBox-${i}`}
                      disabled={false}
                      label={''}
                    />
                  )}
                  {showAstreinteSelect[row.id] === true && (
                    <SelectField
                      readOnly={readOnly}
                      disabled={readOnly}
                      name={`dayAstreinteSelectInput${i}`}
                      id={`dayAstreinteSelectInput${i}`}
                      value={row.astreinte || 0}
                      options={hourHNO}
                      handlechange={(e: any) => {
                        handleAstreinte(row.id, Number(e.target.value));
                      }}
                      label={''}
                      required={false}
                    />
                  )}
                </Box>
              </TableCellBody>
              <TableCell id={`comment-${i}`}>
                <ValidationTextField
                  disabled={readOnly}
                  readOnly={readOnly}
                  type={TypeInput.text}
                  id={`commentField-${i}`}
                  value={row?.comment}
                  maxLength={'50'}
                  onChange={(e) => {
                    debouncedUpdateCra(row.id, e.target.value);
                  }}
                  label={'Commentaire'}
                  placeholder={'Commentaire ...'}
                />
                {!readOnly && (
                  <SingleLineText
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '-1em',
                      paddingRight: ' 0.5em',
                    }}
                    text={`${row?.comment?.length || 0}/50`}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </BasicTable>
      </StyledTable>
    </>
  );
};

const TableCellBody = styled(TableCell)(() => ({
  textAlign: 'center' as CanvasTextAlign,
}));

const StyledTable = styled('div')(({ theme }) => ({
  margin: 'auto',
}));

const ContainerApplyValue = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  width: '700px',
  margin: '1em auto',
  alignItems: 'flex-start',
  alignContent: 'center',
}));
