import './datadogServiceWorkerPolyfill';
import { datadogLogs } from '@datadog/browser-logs';
import { Env, LogLevel, Service } from '@bloomays-lib/types.shared';
import type { LogObject } from 'consola';
import find from 'lodash/find';
import { logLevelToConsolaLevel } from './utils';
import { isObject, safeCircularStringify } from '@bloomays-lib/utils.shared';

const formatMessage = (message: any): string => {
  if (message instanceof Error) {
    return message.message;
  }
  if (isObject(message)) {
    try {
      return safeCircularStringify(message);
    } catch (err) {
      console.warn(err);
      return message.toString();
    }
  } else {
    return message?.toString();
  }
};

const formatContext = (context: any): object => {
  if (!context) {
    return {};
  }
  if (isObject(context)) {
    return context;
  }
  if (Array.isArray(context)) {
    return {
      array: context,
    };
  }
  return {
    context: context,
  };
};

const findError = (args: any[]): Error | undefined => {
  return find(args, (arg) => {
    return arg instanceof Error;
  });
};

export interface DatadogTransportOptions {
  apiKey?: string;
  service: Service;
  source?: string;
  tags?: string[];
  userAgent?: string;
  level: LogLevel;
  env: Env;
  version: string;
}

//
// Inherit from `winston-transport` so you can take advantage
// of the base functionality and `.exceptions.handle()`.
//
export class DatadogTransport {
  opts: DatadogTransportOptions;

  constructor(opts: DatadogTransportOptions) {
    this.opts = opts as DatadogTransportOptions;
    if (this.opts.apiKey) {
      datadogLogs.init({
        clientToken: this.opts.apiKey,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: undefined,
        sessionSampleRate: 100,
        env: opts.env,
        service: opts.service,
        version: opts.version,
      });
    }
  }

  log(logObj: LogObject): void {
    if (logObj.level > logLevelToConsolaLevel(this.opts.level)) {
      return;
    }

    const message = formatMessage(logObj.args[0]);
    const context = formatContext(logObj.args[1]);
    const error = findError(logObj.args);

    switch (logObj.type) {
      case 'debug':
        return datadogLogs.logger.debug(message, context, error);
      case 'info':
        return datadogLogs.logger.info(message, context, error);
      case 'warn':
        return datadogLogs.logger.warn(message, context, error);
      case 'error':
        return datadogLogs.logger.error(message, context, error);
      default:
        return datadogLogs.logger.info(message, context, error);
    }
  }

  drain(): void {
    self.dispatchEvent(new Event('freeze'));
  }
}
