import { ChangeEvent, FocusEventHandler } from 'react';
import RawFormControlLabel from '@mui/material/FormControlLabel';
import MaterialCheckbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export type CheckboxProps = {
  id?: string;
  name: string;
  checked?: boolean;
  required?: boolean;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  label?: string;
  labelPlacement?: 'top' | 'start' | 'end' | 'bottom';
};

const Checkbox = ({
  id,
  name,
  checked = false,
  onBlur,
  onChange,
  disabled = false,
  label = '',
  labelPlacement = 'end',
  required = false,
}: CheckboxProps): JSX.Element => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MaterialCheckbox
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          color="primary"
          required={required}
        />
      }
    />
  );
};

export default Checkbox;

export { Checkbox };

const FormControlLabel = styled(RawFormControlLabel)(() => ({
  margin: '0px !important',
}));
