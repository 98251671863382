/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { notify } from '../../helpers/toastify';

import BasicTable from '../molecules/BasicTable';
import { Button, InformationTip, SingleLineText, TitlePart } from '@bloomays-lib/ui.shared';

import DatePicker, { DatePickerValue } from '../atoms/DatePicker';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ActionBundle } from '../molecules/ActionBundle';
import Modal from '../atoms/Modal';
import InvoicesUpload from '../molecules/InvoicesUpload';
import Typography from '@mui/material/Typography';
import { IActivity, IMission, Role } from '@bloomays-lib/types.shared';
import { ApolloError, ApolloQueryResult, FetchResult } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { convertDateToStringFormat, fixed, lastDateOfMonth } from '@bloomays-lib/utils.shared';
import { getApolloErrorCode } from '../../helpers/error';
import { checkIsFinished } from '../../helpers/CRA';
import { useAuth } from '../../auth/AuthProvider';
import { UploadFileActivity, _ActivityModified } from '../../types/components';
import Stack from '@mui/material/Stack';

export type TableManagementCRAProps = {
  path?: string[];
  error?: ApolloError;
  displayNewActivity: boolean;
  displayRow: () => void;
  newActivityValue: DatePickerValue;
  arrayOfCRA: _ActivityModified[];
  cancelInviteSignCRA: (idDocument: string, row: number | null) => Promise<void>;
  responseSign: {
    status: string;
    idRow: string | number | null;
  };
  modalUploadCRA: {
    show: boolean;
    id?: string | null;
  };
  responseUploadCRA: {
    status: string;
    idRow: string | null;
  };
  uploadCRA: UploadFileActivity;
  uploadInvoice: UploadFileActivity;
  modalUploadBill: { show: boolean; id?: null | string; urlInvoiceS3?: string; urlAdditionalInvoicesS3?: string };
  setModalUploadBill: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id?: string | null;
      urlInvoiceS3?: string;
      urlAdditionalInvoicesS3?: string;
    }>
  >;
  setNewActivityValue: React.Dispatch<React.SetStateAction<DatePickerValue>>;
  mission: IMission;
  getEmbedBloomerCRA: (activityId: string, to: string) => Promise<void>;
  CRAUrlEmbed: string | null;
  refetchActivities: (
    variables?:
      | Partial<{
          recordId: string;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<any>>;
  setResponseSign: React.Dispatch<
    React.SetStateAction<{
      status: string;
      idRow: string | number | null;
    }>
  >;
  upsertFollowUp: (args: {
    selected?: number;
    comment?: string;
    collectDate: Date;
    activityId: string;
  }) => Promise<FetchResult<any> | undefined>;
  stateNPS: { loading: boolean };
  getNPS: any;
  deleteInvoice: (activityRecordID: string) => Promise<void>;
};

const TableManagementCRA = ({
  stateNPS,
  error,
  displayNewActivity,
  displayRow,
  newActivityValue,
  arrayOfCRA,
  cancelInviteSignCRA,
  responseSign,
  modalUploadCRA,
  responseUploadCRA,
  uploadCRA,
  uploadInvoice,
  modalUploadBill,
  setModalUploadBill,
  setNewActivityValue,
  mission,
  getEmbedBloomerCRA,
  CRAUrlEmbed,
  refetchActivities,
  setResponseSign,
  upsertFollowUp,
  getNPS,
  deleteInvoice,
}: TableManagementCRAProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const param = useParams<{ missionRecordId: string }>();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (modalUploadBill?.show === true) {
      handleOpen();
    }
    if (modalUploadBill?.show === false) {
      handleClose();
    }
  }, [modalUploadBill]);

  if (error) {
    let textError;
    const errorCode = getApolloErrorCode(error);
    switch (errorCode) {
      case 'UNKNOWN_RECORD_ID':
        textError = "Cette mission n'existe pas";
        break;
      case 'OBJECT_NOT_OWNED':
        textError = "L'id de cette mission ne semble pas vous appartenir";
        break;
      default:
        textError = 'une erreur non identifiée est survenue';
    }
    notify('error', 'Impossible de récupérer tes données. Erreur du server, essaie plus tard ! 😓 ', textError);
  }

  const createRow = (dateSelected: Date) => {
    const arrayOfCRACopy = [...arrayOfCRA];
    let valueToObjectDate;
    if (newActivityValue) {
      valueToObjectDate = new Date(newActivityValue);
    } else {
      valueToObjectDate = new Date();
    }
    const exist = checkExistingCRA(arrayOfCRACopy, valueToObjectDate);
    if (exist?.length > 0) return;

    const finished = checkIsFinished(mission as IMission, valueToObjectDate);
    if (finished) return;
    const date = convertDateToStringFormat(lastDateOfMonth(dateSelected), 'yyyy-MM-dd');
    navigate(`/missions/${param.missionRecordId}/cra/${date}`);
  };

  const checkExistingCRA = (arrayCRA: _ActivityModified[], date: Date) => {
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    const isExist = arrayCRA.filter((cra) => {
      return cra.monthNumber === selectedMonth && cra.date.getFullYear() === selectedYear;
    });
    if (isExist.length > 0) {
      notify('info', 'Le CRA pour ce mois-ci existe déjà !');
    }
    return isExist;
  };

  const editionPage = (dateSelected: Date) => {
    const date = convertDateToStringFormat(lastDateOfMonth(dateSelected), 'yyyy-MM-dd');
    navigate(`/missions/${param.missionRecordId}/cra/${date}`);
  };

  const isAdmin = auth?.user?.roles?.includes(Role.Admin);
  const bloomerUsePortage = mission.bloomerBillingSociety?.[0]?.portage === true;

  const displayStatut = (el: IActivity) => {
    if (el.recordId) {
      if (!el?.urlInvoicesS3) {
        switch (el.CRAStatus) {
          case 'Non crée':
            return 'CRA non créé';
          case 'Validé client':
            return `Validé Client, en attente de la facture ${bloomerUsePortage ? 'de portage' : 'Bloomer'}`;
          case 'Forcé Bloomays':
            return `Validé Bloomays, en attente de la facture ${bloomerUsePortage ? 'de portage' : 'Bloomer'}`;
          case 'Attente signature Client':
            return 'En cours de signature du client';
          case 'Attente signature Bloomer':
            return 'En attente de signature Bloomer';
          case 'Signature non créee':
            return 'CRA à valider par signature Bloomer';
          default:
            return 'En cours de traitement';
        }
      } else {
        if (!el.payed && el.lastSupplierError) {
          return (
            <span title={el.lastSupplierError}>
              🕦 Facture {bloomerUsePortage ? 'de portage' : 'Bloomer'} en cours de vérification
            </span>
          );
        }
        if (el.payed) {
          return `Facture ${bloomerUsePortage ? 'de portage' : 'Bloomer'} traitée`;
        }
        return `Facture ${bloomerUsePortage ? 'de portage' : 'Bloomer'} receptionnée`;
      }
    } else {
      return "En cours d'édition";
    }
  };

  const getPlural = (nb: number) => {
    const sentence = `${fixed(nb, 4)} jour`;
    if (nb > 1) {
      return sentence + 's';
    }
    return sentence;
  };

  const getExplanation = (el: IActivity) => {
    return `${getPlural(el.nbDaysWorked)} + ${getPlural(el.nbDaysMarkupWorked)} samedi + ${getPlural(
      fixed(el.nbDaysMarkup2Worked + (el.astreinte || 0)),
    )} dimanche / férié / astreinte`;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        additionnalCSS={{
          borderRadius: '20px',
          width: '622px',
          padding: '32px',
          border: 'none',
        }}
      >
        <InvoicesUpload
          urlInvoicesS3={modalUploadBill?.urlInvoiceS3}
          urlAdditionalInvoicesS3={modalUploadBill?.urlAdditionalInvoicesS3}
          id={modalUploadBill?.id && modalUploadBill?.id}
          responseUploadCRA={responseUploadCRA}
          uploadInvoice={uploadInvoice}
        />
      </Modal>
      <TitlePart textTitle="Gestion des CRA" />
      <SingleLineText
        text="Concernant ta mission chez "
        span={mission?.clientSocietyName?.[0] ?? '...'}
        sx={{ margin: '12px 0' }}
      />
      <SingleLineText text="Pour renseigner ton compte rendu d'activité, clique sur le bouton 'Ajouter un CRA', puis sélectionne le mois concerné et déclare les jours où tu as travaillé." />
      <SingleLineText text="Une fois le CRA validé, tu recevras un mail pour uploader ta facture. Sans cette dernière étape, nous ne pouvons pas procéder au paiement de ta prestation. On compte sur toi ! :)" />

      <StyledTable>
        <BasicTable columns={['Mois', 'Année', 'Total jours travaillés', 'Statut', 'Action']}>
          {arrayOfCRA?.map((el, i) => (
            <TableRow key={i}>
              <TableCellBody>{el.month}</TableCellBody>
              <TableCellBody>{el.year}</TableCellBody>
              <TableCellBody>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  {el.nbTotalDaysWorked} <InformationTip>{getExplanation(el)}</InformationTip>
                </Stack>
              </TableCellBody>
              <TableCellBody>
                <TextStatusTypography variant="body1Light">{displayStatut(el)}</TextStatusTypography>
              </TableCellBody>
              <TableCell>
                <ContainerSendSign>
                  <ActionBundle
                    getNPS={getNPS}
                    stateNPS={stateNPS}
                    upsertFollowUp={upsertFollowUp}
                    mission={mission}
                    setResponseSign={setResponseSign}
                    refetchActivities={refetchActivities}
                    CRAUrlEmbed={CRAUrlEmbed}
                    sendFunc={async () => {
                      if (!el.contactOperations) {
                        notify(
                          'warning',
                          "Il semblerait qu'il manque des infos sur ton contact opérationnel, rapproche toi de ton custom success manager !",
                        );
                      } else {
                        if (el.recordId) {
                          await getEmbedBloomerCRA(el.recordId, el.contactOperations);
                        }
                      }
                    }}
                    activity={el}
                    modalUploadCRA={modalUploadCRA}
                    responseUploadCRA={responseUploadCRA}
                    uploadCRA={uploadCRA}
                    i={i}
                    responseSign={responseSign}
                    CRAEditing={!el.validated && !el.sign && el.urlCraS3 === null}
                    CRACanSendInvite={el.nbTotalDaysWorked !== null || el.nbTotalDaysWorked !== undefined}
                    CRAUploaded={el.urlCraS3 === null && el.nbTotalDaysWorked !== 0}
                    CRASignSent={!el.validated && el.sign}
                    CRAValidated={el.validated}
                    canSeeInvoices={isAdmin || !bloomerUsePortage}
                    canUploadInvoices={
                      el.urlInvoicesS3 === null && el?.payed !== true && (isAdmin || !bloomerUsePortage)
                    }
                    editFunc={() => editionPage(el.date)}
                    cancelFunc={() => {
                      if (el.signingDocumentId) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        cancelInviteSignCRA(el.signingDocumentId, i);
                      }
                    }}
                    setModalUploadBill={() => {
                      setModalUploadBill({
                        show: true,
                        id: el.recordId,
                        urlInvoiceS3: el.urlInvoicesS3,
                        urlAdditionalInvoicesS3: el.urlAdditionalInvoicesS3,
                      });
                    }}
                    canDeleteInvoices={isAdmin || !bloomerUsePortage}
                    deleteInvoice={deleteInvoice}
                  />
                </ContainerSendSign>
              </TableCell>
            </TableRow>
          ))}
        </BasicTable>
        {displayNewActivity && (
          <ContainerDisplayNewCRA>
            <DatePicker label="CRA à déclarer" value={newActivityValue as Date} handleChange={setNewActivityValue} />
            <Button
              sx={{ margin: '1em auto 0' }}
              textButton="Continuer"
              onClick={() => {
                if (newActivityValue) {
                  createRow(new Date(newActivityValue));
                }
              }}
            />
          </ContainerDisplayNewCRA>
        )}
        {!displayNewActivity && (
          <Button
            id="addCra"
            disable={!!error}
            textButton="Ajouter un CRA"
            onClick={() => displayRow()}
            emoji={<i className="fas fa-plus"></i>}
          />
        )}
      </StyledTable>
    </div>
  );
};

export default TableManagementCRA;
export { TableManagementCRA };

const TableCellBody = styled(TableCell)(() => ({
  textAlign: 'center !important' as CanvasTextAlign,
}));

const ContainerDisplayNewCRA = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: '2px 2px 20px lightgrey',
  borderRadius: 10,
  width: '40%',
  margin: '1em auto',
  padding: '1em',
  '@media screen and (max-width: 760px)': {
    width: '75%',
  },
}));

const StyledTable = styled('div')(({ theme }) => ({
  width: '90%',
  margin: '1em auto',
}));

const ContainerSendSign = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  msFlexDirection: 'column',
  flexFlow: 'wrap',
  justifyContent: 'center',
  '@media screen and (max-width: 620px)': {
    width: 'auto',
  },
}));

const TextStatusTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  display: 'inline-block',
  margin: 'auto',
  color: 'gray',
  padding: '0 0.5em',
}));
