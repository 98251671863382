import { useRef, useState, forwardRef, useImperativeHandle, ForwardedRef, useEffect, useMemo } from 'react';
import RawHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IBAN, BIC } from 'ibankit';
import { styled } from '@mui/material/styles';

import { Button, TypeInput } from '@bloomays-lib/ui.shared';
import { ValidationTextField } from '@bloomays-lib/ui.shared';
import { Upload } from '../molecules/Upload';
import {
  SocietyRegistrationNumberTextFieldMode,
  SocietyRegistrationNumberTextField,
} from '../molecules/SocietyRegistrationNumberTextField';
import { TitlePart } from '@bloomays-lib/ui.shared';
import path from 'path';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import useCustomLocalStorage from '../../customHooks/useCustomLocalStorage';
import { AddressCountryCodeEU, ISociety } from '@bloomays-lib/types.shared';
import { Logger } from '../../services/serviceLogger';
import { Option, SelectField } from '../atoms/SelectField';
import VATTextField from '../molecules/VATTextField';
import CountrySelector from '../molecules/CountrySelector';
import { addDayToDate, dateFormat } from '@bloomays-lib/utils.shared';

const logger = Logger('EditBloomerSociety');

export type EditBloomerSocietyProps = {
  onValidated: (data: ISociety) => Promise<ISociety | undefined>;
  society?: ISociety;
  hideSubmitButton?: boolean;
};

const frenchSocietyStatusOptions: Option[] = [
  { value: 'Auto Entrepreneur', label: '🇫🇷 Auto Entrepreneur', disabled: true },
  { value: 'EI', label: '🇫🇷 Entreprise Individuelle / Micro-entreprise (ex Auto Entrepreneur)' },
  { value: 'EURL', label: '🇫🇷 EURL' },
  { value: 'SARL', label: '🇫🇷 SARL' },
  { value: 'SAS', label: '🇫🇷 SAS' },
  { value: 'SASU', label: '🇫🇷 SASU' },
  { value: 'Autre', label: '🇫🇷 Autre forme Française' },
  { value: 'SUE', label: '🇪🇺 Societé Union Européenne' },
  { value: 'SHUE', label: '🌍 Societé Hors Union Européenne' },
];

const EditBloomerSociety = forwardRef(
  (
    { onValidated, society: bloomerSociety, hideSubmitButton }: EditBloomerSocietyProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const initFile = (currentSociety?: ISociety, property?: string): any => {
      const keyOfSociety = property as keyof ISociety;
      return currentSociety?.[keyOfSociety]
        ? {
            path: path.basename(currentSociety?.[keyOfSociety] as string),
            url: currentSociety?.[keyOfSociety] as string,
          }
        : undefined;
    };

    const isInSocietyOptions = (value: string | undefined, options: Option[]): boolean => {
      return options.some((option) => option.value === value);
    };

    const prepareSociety = (currentSociety?: ISociety) => {
      if (!currentSociety) return;
      return {
        portage: currentSociety.portage || false,
        recordId: currentSociety.recordId,
        name: currentSociety.name,
        siret: currentSociety.siret,
        status: currentSociety.status,
        KBIS: initFile(currentSociety, 'KBIS'),
        URSSAFVigilanceCertificate: initFile(currentSociety, 'URSSAFVigilanceCertificate'),
        URSSAFVCExpirationDate: currentSociety.URSSAFVCExpirationDate,
        RCPVigilanceCertificate: initFile(currentSociety, 'RCPVigilanceCertificate'),
        IBAN: currentSociety.IBAN,
        BIC: currentSociety.BIC,
        tva: currentSociety.tva,
      };
    };

    const UploadKBISRef = useRef<any>(null);
    const UploadURSSAFRef = useRef<any>(null);
    const UploadRCPRef = useRef<any>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [KBIS, setKBIS] = useState<any | undefined | null>(initFile(bloomerSociety, 'KBIS'));
    const [URSSAF, setURSSAF] = useState<any | undefined | null>(
      initFile(bloomerSociety, 'URSSAFVigilanceCertificate'),
    );
    const [RCP, setRCP] = useState<any | undefined | null>(initFile(bloomerSociety, 'RCPVigilanceCertificate'));
    const [URSSAFVCExpirationDate, setURSSAFVCExpirationDate] = useState<string | undefined>(
      bloomerSociety ? bloomerSociety.URSSAFVCExpirationDate : undefined,
    );
    const [formSociety, setFormSociety] = useCustomLocalStorage<ISociety | undefined>(
      'bloomerSociety',
      prepareSociety(bloomerSociety),
    );

    useEffect(() => {
      setFormSociety(prepareSociety(bloomerSociety));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bloomerSociety]);

    logger.debug('bloomerSociety', bloomerSociety);
    logger.debug('society', formSociety);
    const [preventText, setPreventText] = useState(false);
    const statuts: Option[] = frenchSocietyStatusOptions;
    const statutsPortage = useMemo(() => {
      return frenchSocietyStatusOptions.slice(2);
    }, []);

    const {
      isMicroEntrepreneur,
      societyKBISLabel,
      societyAccord,
      requiredVATNumber,
      showVATTextField,
      fieldMode,
      kbisRequired,
      urssafRequired,
      societyTaxesLabel,
      isForeignSociety,
      isEuropeanSociety,
    } = useMemo((): {
      isMicroEntrepreneur: boolean;
      isEuropeanSociety: boolean;
      isForeignSociety: boolean;
      societyKBISLabel: string;
      societyAccord: string;
      requiredVATNumber: boolean;
      showVATTextField: boolean;
      fieldMode: SocietyRegistrationNumberTextFieldMode;
      kbisRequired: boolean;
      urssafRequired: boolean;
      societyTaxesLabel: string;
    } => {
      let fieldMode: SocietyRegistrationNumberTextFieldMode;
      let isMicroEntrepreneur;
      let isEuropeanSociety;
      let isForeignSociety;
      let requiredVATNumber;
      let showVATTextField;
      let kbisRequired = true;
      switch (formSociety?.status) {
        case 'Auto Entrepreneur':
        case 'EI':
          isEuropeanSociety = false;
          isMicroEntrepreneur = true;
          requiredVATNumber = false;
          isForeignSociety = false;
          showVATTextField = true;
          fieldMode = 'siret';
          break;
        case 'SUE':
          isEuropeanSociety = true;
          isMicroEntrepreneur = false;
          requiredVATNumber = false;
          showVATTextField = false;
          fieldMode = 'european';
          isForeignSociety = false;
          break;
        case 'SHUE':
          isEuropeanSociety = false;
          isMicroEntrepreneur = false;
          isForeignSociety = true;
          requiredVATNumber = false;
          showVATTextField = false;
          fieldMode = 'foreign';
          isForeignSociety = true;
          break;
        default:
          requiredVATNumber = true;
          fieldMode = 'siret';
          isMicroEntrepreneur = false;
          isEuropeanSociety = false;
          isForeignSociety = false;
          requiredVATNumber = true;
          showVATTextField = true;
          isForeignSociety = false;
          break;
      }
      const societyKBISLabel =
        isEuropeanSociety || isForeignSociety
          ? "certificat de création d'entreprise"
          : formSociety?.portage || !isMicroEntrepreneur
          ? 'KBIS'
          : 'KBIS, attestation Sirene, INSEE';
      const societyTaxesLabel =
        isEuropeanSociety || isForeignSociety || isMicroEntrepreneur
          ? 'attestation de réglement des impôts et taxes locales'
          : 'Certificat Vigilance URSSAF';
      const societyKBISAccord = formSociety?.portage ? 'le' : 'ton';
      const urssafRequired = formSociety?.portage !== true && !isMicroEntrepreneur;
      kbisRequired = formSociety?.portage ? false : true;
      return {
        isMicroEntrepreneur,
        isEuropeanSociety,
        isForeignSociety,
        societyKBISLabel,
        societyAccord: societyKBISAccord,
        requiredVATNumber,
        showVATTextField,
        fieldMode,
        kbisRequired,
        urssafRequired,
        societyTaxesLabel,
      };
    }, [formSociety?.status, formSociety?.portage]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => ({
      validate(e: any) {
        return onSubmit(e);
      },
    }));

    const onSubmit = async (e: { preventDefault: () => void }) => {
      logger.debug('onSubmit');
      e.preventDefault();
      if (!UploadKBISRef.current.validate()) {
        UploadKBISRef.current?.scrollIntoView();
        return false;
      }
      if (!UploadURSSAFRef.current.validate()) {
        UploadURSSAFRef.current?.scrollIntoView();
        return false;
      }
      if (!UploadRCPRef.current.validate()) {
        UploadRCPRef.current?.scrollIntoView();
        return false;
      }
      try {
        if (formSociety) {
          formSociety.KBIS = KBIS;
          formSociety.URSSAFVigilanceCertificate = URSSAF;
          formSociety.RCPVigilanceCertificate = RCP;
          formSociety.URSSAFVCExpirationDate = URSSAFVCExpirationDate;
          setUploading(true);
          if (!isForeignSociety && !isEuropeanSociety) {
            formSociety.addressCountryCode = 'FR';
          }
          if (isEuropeanSociety && !formSociety.tva) {
            formSociety.tva = formSociety.siret;
          }
          const updatedSociety = await onValidated(formSociety);
          if (updatedSociety) {
            setFormSociety(prepareSociety(updatedSociety));
          }
          setUploading(false);
          setPreventText(false);
        }
      } catch (err) {
        setUploading(false);
      }
      return true;
    };

    const selectSocietyOption = formSociety?.portage ? statutsPortage : statuts;

    return (
      <Container>
        <TitlePart textTitle="À propos de qui va nous envoyer" span="ta facture" />
        <ValidationTextField
          aria-label="bloomersocietyinfo-name"
          id="societyName"
          name="societyName"
          label="Nom"
          value={formSociety?.name || ''}
          type={TypeInput.text}
          required={true}
          onChange={(e) => {
            setFormSociety({
              ...formSociety,
              name: e.target.value,
            } as ISociety);
          }}
          formElement={true}
        />
        <SelectField
          data-testid="portage"
          aria-label="bloomersocietyinfo-portage"
          id="societyPortage"
          name="societyPortage"
          label="C'est une société de portage ?"
          defaultOption={true}
          value={formSociety?.portage === undefined ? '' : formSociety?.portage ? '1' : '0'}
          required={true}
          options={[
            { value: '0', label: 'Non' },
            { value: '1', label: 'Oui' },
          ]}
          handlechange={(e) => {
            e.preventDefault();
            const isPortage = e.target.value === '1';
            if (isMicroEntrepreneur && isPortage) {
              setFormSociety({
                ...formSociety,
                status: undefined,
                portage: isPortage,
              } as ISociety);
            } else {
              setFormSociety({
                ...formSociety,
                portage: isPortage,
              } as ISociety);
            }
          }}
        />
        <SelectField
          data-testid="societyStatus"
          aria-label="bloomersocietyinfo-status"
          id="societyStatus"
          name="societyStatus"
          label="Status (forme juridique)"
          defaultOption={true}
          value={isInSocietyOptions(formSociety?.status, selectSocietyOption) ? formSociety?.status : ''}
          required={true}
          options={selectSocietyOption}
          handlechange={(e) => {
            e.preventDefault();
            setFormSociety({
              ...formSociety,
              status: (e.target as HTMLInputElement).value,
            } as ISociety);
          }}
        />
        <CountrySelector
          id="societyCountry"
          name="societyCountry"
          value={formSociety?.addressCountryCode || undefined}
          required={true}
          onChange={(e) => {
            setFormSociety({
              ...formSociety,
              addressCountryCode: e?.iso2,
            } as ISociety);
          }}
          allowedCountryCode={(code) => {
            if (isForeignSociety) {
              return code !== 'FR' && !AddressCountryCodeEU.includes(code);
            } else {
              if (isEuropeanSociety) {
                return code !== 'FR' && AddressCountryCodeEU.includes(code);
              }
              return code === 'FR';
            }
          }}
          hidden={!(isForeignSociety || isEuropeanSociety)}
        />
        <SocietyRegistrationNumberTextField
          fieldMode={fieldMode}
          aria-label="bloomersocietyinfo-siret"
          id="societySiret"
          name={formSociety?.siret}
          required={true}
          value={formSociety?.siret || ''}
          onChange={(e) => {
            if ((e.target as HTMLInputElement).value !== bloomerSociety?.siret && bloomerSociety?.siret) {
              setFormSociety((ps) => {
                return {
                  ...(ps || {}),
                  siret: (e.target as HTMLInputElement).value,
                  portage: undefined,
                  name: '',
                  status: '',
                  IBAN: '',
                  BIC: '',
                  tva: '',
                };
              });
              setKBIS(null);
              setURSSAF(null);
              setRCP(null);
              setPreventText(true);
            } else {
              setFormSociety({
                ...formSociety,
                KBIS: undefined,
                siret: (e.target as HTMLInputElement).value,
              } as ISociety);
              setPreventText(false);
            }
          }}
          formElement={true}
        />
        {preventText && (
          <SingleLineText
            text="Attention en changeant le siret, tu t'apprêtes à créer une nouvelle société !"
            sx={{ paddingBottom: '1em', color: 'red' }}
          />
        )}
        <VATTextField
          aria-label="bloomersocietyinfo-vat"
          id="societySiret"
          name={formSociety?.tva}
          hidden={!showVATTextField}
          required={requiredVATNumber}
          value={formSociety?.tva || ''}
          onChange={(e) => {
            setFormSociety({
              ...formSociety,
              KBIS: undefined,
              tva: (e.target as HTMLInputElement).value,
            } as ISociety);
          }}
          formElement={true}
        />
        {isMicroEntrepreneur && (
          <SingleLineText
            text="Le numéro de TVA est obligatoire si assujeti à la TVA pour traiter vos factures."
            sx={{ paddingBottom: '1em' }}
          />
        )}
        <ValidationTextField
          aria-label="bloomersocietyinfo-iban"
          id="societyIban"
          name={formSociety?.IBAN}
          label="IBAN"
          value={formSociety?.IBAN || ''}
          type={TypeInput.text}
          placeholder="FR12 1234 1234 1234 1234 1234 12"
          hidden={formSociety?.portage}
          required={!formSociety?.portage}
          onChange={(e) => {
            if (!IBAN.isValid((e.target as HTMLInputElement).value)) {
              (e.target as HTMLInputElement).setCustomValidity('IBAN mal formé');
            } else {
              (e.target as HTMLInputElement).setCustomValidity('');
            }
            setFormSociety({
              ...formSociety,
              IBAN: (e.target as HTMLInputElement).value,
            } as ISociety);
          }}
          formElement={true}
        />
        <ValidationTextField
          aria-label="bloomersocietyinfo-bic"
          id="societyBic"
          name={formSociety?.BIC}
          label="BIC"
          value={formSociety?.BIC || ''}
          type={TypeInput.text}
          minLength={'8'}
          maxLength={'11'}
          hidden={formSociety?.portage}
          placeholder="8 à 11 caractères"
          required={!formSociety?.portage}
          onChange={(e) => {
            if (!BIC.isValid(e.target.value)) {
              e.target.setCustomValidity('BIC mal formé');
            } else {
              e.target.setCustomValidity('');
            }
            setFormSociety({
              ...formSociety,
              BIC: e.target.value,
            } as ISociety);
          }}
          formElement={true}
        />
        <Upload
          aria-label="bloomersocietyinfo-kbis"
          ref={UploadKBISRef}
          id="KBIS"
          placeholder={societyKBISLabel}
          textLabel={`Ajouter ${societyAccord}  ${societyKBISLabel}`}
          name={'KBIS'}
          fileTypes={['.pdf']}
          displayIcons={true}
          maxFiles={1}
          maxLength={5000000}
          preloadedFiles={KBIS ? [KBIS] : []}
          handleChange={(files) => setKBIS(files.length ? files[0] : null)}
          autoload={true}
          required={kbisRequired}
        />
        {preventText && formSociety?.recordId && (
          <SingleLineText
            text="Tu t'apprêtes à créer une nouvelle société, tu dois donc uploader un nouveau KBIS / attestation sirene / INSEE"
            sx={{ paddingBottom: '1em' }}
          />
        )}
        <Upload
          aria-label="bloomersocietyinfo-urssaf"
          ref={UploadURSSAFRef}
          id="URSSAFVigilanceCertificate"
          placeholder={societyTaxesLabel}
          textLabel={`Ajouter ${societyAccord} ${societyTaxesLabel}`}
          name={'URSSAFVigilanceCertificate'}
          fileTypes={['.pdf']}
          displayIcons={true}
          maxFiles={1}
          maxLength={5000000}
          preloadedFiles={URSSAF ? [URSSAF] : []}
          handleChange={(files) => {
            setURSSAF(files.length ? files[0] : null);
            const expirationDate = addDayToDate(new Date(), 15);
            setURSSAFVCExpirationDate(dateFormat(expirationDate, 'yyyy-MM-dd'));
          }}
          autoload={true}
          required={urssafRequired}
        />
        <Upload
          aria-label="bloomersocietyinfo-rcp"
          ref={UploadRCPRef}
          id="RCPVigilanceCertificate"
          placeholder="Certificat Vigilance RCP"
          textLabel={`Ajouter ${societyAccord} Certificat Vigilance RCP`}
          name={'RCPVigilanceCertificate'}
          fileTypes={['.pdf']}
          displayIcons={true}
          maxFiles={1}
          maxLength={5000000}
          preloadedFiles={RCP ? [RCP] : []}
          handleChange={(files) => setRCP(files.length ? files[0] : null)}
          autoload={true}
          required={false}
        />
        <ContainerQuestionRCP>
          <HelpOutlineIcon />
          <ContainerRCP>
            <SingleLineText
              text={
                "RCP, c'est quoi ? 👉 Votre entreprise peut causer dans le cadre de son activité un préjudice à un tiers. Ce dernier relève de la Responsabilité Civile Professionnelle  de votre entreprise. L’assurance RC Pro couvre alors votre entreprise en remboursant à la victime concernée tout ou partie des dommages occasionnés."
              }
            />
          </ContainerRCP>
        </ContainerQuestionRCP>
        {!hideSubmitButton && (
          <Button
            buttonType="submit"
            // eslint-disable-next-line jsx-a11y/aria-role
            role={'button-editbloomersociety'}
            none={'none'}
            textButton={uploading ? "En cours d'envoi" : 'Sauvegarder mes données'}
            disable={uploading}
          />
        )}
      </Container>
    );
  },
);

export default EditBloomerSociety;

export { EditBloomerSociety };
EditBloomerSociety.displayName = 'EditBloomerSociety';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: '2px 2px 20px lightgrey',
  borderRadius: '10px',
  width: '55%',
  margin: '1em auto',
  padding: '1.5em',
}));

const ContainerRCP = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
}));

const ContainerQuestionRCP = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.greenLight,
  border: `1px solid ${theme.palette.tertiary.greenMedium}`,
}));

const HelpOutlineIcon = styled(RawHelpOutlineIcon)(() => ({
  paddingRight: 10,
}));
