import { object, string, number, boolean, array, date, bool } from 'yup';
import { AddressCountryCodeEU } from '../types.shared';

export const FR_SIRET_REGEX_STRING = '^\\d{14}$';
export const EU_VAT_REGEX_STRING =
  'ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CHE[0-9]{8,10}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES(X(-|\\.)?0?\\d{7}(-|\\.)?[A-Z]|[A-Z](-|\\.)?\\d{7}(-|\\.)?[0-9A-Z]|\\d{8}(-|\\.)?[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2})';
export const EU_VAT_SIRET_REGEX_STRING = `${EU_VAT_REGEX_STRING}|${FR_SIRET_REGEX_STRING}`;
export const euVATSiretRegex = new RegExp(EU_VAT_SIRET_REGEX_STRING);
const euVATRegex = new RegExp(EU_VAT_REGEX_STRING);
const yupVATSiret = string().matches(
  euVATSiretRegex,
  'Le siret de la societé doit être un numéro de TVA ou un numéro de SIRET valide',
);
const yupVAT = string().matches(euVATRegex, 'Le siret de la societé doit être un numéro de TVA valide');
const schemas: any = {};

schemas.invoice = object({
  invoiceDate: date().required(),
  paymentDate: date().required(),
  title: string().required(),
  draft: boolean().required(),
  currencyISO4217: string().required().is(['EUR']),
  specialMention: string(),
  internalReference: string().required(),
  langage: string().required().oneOf(['fr_FR', 'en_GB']),
  type: string().required().oneOf(['FREE', 'CDI']),
  subtype: string().required('type of mission must be defined (IT, RPO, People').oneOf(['IT', 'RPO', 'People']),
  orderNumber: string().optional(),
  items: array()
    .required()
    .min(1)
    .of(
      object({
        reference: string().required(),
        label: string().required(),
        quantity: number().required(),
        unitAmountWithoutTaxe: number().required(),
        unit: string().required('Only day unit typ is supported').oneOf(['day']),
        vatRate: number().required('Only VAT RATE of 0 | 5.5 | 10 | 20 are supported').oneOf([0, 5.5, 10, 20]),
        description: string().required(),
        discountPercent: number().optional(),
      }),
    ),
  customerInfos: object({
    customerName: string().required(),
    siret: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVATSiret;
      })
      .required(),
    billingAddressName: string().required(),
    billingAddressPart1: string().required(),
    billingAddressPart2: string().optional(),
    billingPostalCode: string().required(),
    billingCity: string().required(),
    billingCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de facturation doit contenir deux caractères")
      .required(),
    deliveryAddressName: string().required(),
    deliveryAddressPart1: string().required(),
    deliveryAddressPart2: string().optional(),
    deliveryPostalCode: string().required(),
    deliveryCity: string().required(),
    deliveryCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de livraison doit contenir deux caractères")
      .required(),
    recipientName: string().required(),
    vatNumber: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVAT;
      })
      .optional(),
    paymentConditions: string().required().oneOf(['0', '15', '30', '45', '60', 'custom']),
    billingContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string().email().required(),
          nameJob: string().optional(),
        }),
      ),
    operationalContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string().email().required(),
          nameJob: string().optional(),
        }),
      ),
    phone: string().optional(),
    internalId: string().required(),
  }),
});

schemas.supplierInvoice = object({
  invoiceDate: date().required(),
  paymentDate: date().required(),
  currencyISO4217: string().required().is(['EUR']),
  internalReference: string().required(),
  langage: string().required().oneOf(['fr_FR', 'en_GB']),
  subtype: string().required('type of mission must be defined (IT, RPO, People').oneOf(['IT', 'RPO', 'People']),
  fileB64Encoded: string().required(),
  draft: boolean().required(),
  items: array()
    .required()
    .min(1)
    .of(
      object({
        label: string().required(),
        quantity: number().required(),
        unitAmountWithoutTaxe: number().required(),
        planItemNumber: string().optional(),
        vatRate: number().required('Only VAT RATE of 0 | 5.5 | 10 | 20 are supported').oneOf([0, 5.5, 10, 20]),
      }),
    ),
  supplierInfos: object({
    supplierName: string().required(),
    siret: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVATSiret;
      })
      .required(),
    billingAddressName: string(),
    billingAddressPart1: string(),
    billingAddressPart2: string(),
    billingPostalCode: string(),
    billingCity: string(),
    billingCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de facturation doit contenir deux caractères")
      .required(),
    vatNumber: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVAT;
      })
      .optional(),
    paymentConditions: string().required().oneOf(['0', '15', '30', '45', '60', 'custom']),
    billingContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string()
            .email('Email mal formé pour le contact de facturation de la societé fournisseur')
            .required('Email obligatoire pour le contact de facturation de la societé fournisseur'),
          nameJob: string().optional(),
        }),
      ),
    phone: string().optional(),
    internalId: string().required(),
  }),
});

schemas.KAM = object({
  firstname: string().required('KAM: Prénom manquant'),
  lastname: string().required('KAM: Nom manquant'),
  email: string().required('KAM: Email manquant').email(),
  phoneNumber: string().optional(),
  bloomers: array().optional(),
  billing: array().optional(),
});

schemas.bloomer = object({
  firstname: string().required('Bloomer: Prénom manquant'),
  lastname: string().required('Bloomer: Nom manquant'),
  email: string().required('Bloomer: Email manquant').email(),
  accountManagerName: string().required(`Bloomer: il manque le nom de l'Account Manager`),
  phoneNumber: string().required(`Bloomer: il manque le numéro de téléphone du candidat`),
  accountManager: array().of(schemas.KAM.required(`Bloomer: Les données de l'Account Manager sont manquantes`)),
});

schemas.contact = object({
  firstname: string().required('Contact Opérationnel : Prénom manquant'),
  lastname: string().required('Contact Opérationnel : Nom manquant'),
  nameJob: string().required('Contact Opérationnel : Intitulé du poste manquant'),
  email: string().required('Contact Opérationnel : Email manquant').email(),
  phoneNumber: string().optional(),
  isBilling: boolean().optional(),
  isOperational: boolean().optional(),
});

schemas.billingContact = object({
  firstname: string().required('Contact de Facturation: Prénom manquant'),
  lastname: string().required('Contact de Facturation: Nom manquant'),
  nameJob: string().required('Contact de Facturation: Intitulé du poste manquant'),
  email: string().required('Contact de Facturation: Email manquant').email(),
  phoneNumber: string().optional(),
  isBilling: boolean().optional(),
  isOperational: boolean().optional(),
});

schemas.legalContact = object({
  firstname: string().required('Contact Légal: Prénom manquant'),
  lastname: string().required('Contact Légal: Nom manquant'),
  nameJob: string().required('Contact Légal: Intitulé du poste manquant'),
  email: string().required('Contact Légal: Email manquant').email(),
  phoneNumber: string().optional(),
  isBilling: boolean().optional(),
  isOperational: boolean().optional(),
});

schemas.society = object({
  name: string().required('Société cliente: Nom manquant'),
  siret: string()
    .when('addressCountrycode', (addressCountrycode, schema) => {
      if (!AddressCountryCodeEU?.includes(addressCountrycode)) {
        return schema;
      }
      return yupVATSiret;
    })
    .required('Société cliente: Siret manquant'),
  tva: string().notRequired().matches(euVATRegex, 'Société cliente: TVA au mauvais format'),
  addressName: string().optional(),
  addressPart1: string().required('Société cliente: adresse manquante'),
  addressZip: string().required('Société cliente: code postal manquant'),
  addressTown: string().required('Société cliente: ville manquante'),
  addressCountrycode: string()
    .length(2, "Le code pays addressCountryCode de l'adresse de la societé doit contenir deux caractères")
    .optional(),
  billingContact: array().of(string()),
});

schemas.billingSociety = object({
  name: string().required('Société de facturation: Nom manquant'),
  siret: string()
    .when('addressCountrycode', (addressCountrycode, schema) => {
      if (!AddressCountryCodeEU?.includes(addressCountrycode)) {
        return schema;
      }
      return yupVATSiret;
    })
    .required('Société de facturation: Siret manquant'),
  tva: string(),
  addressName: string().optional(),
  addressPart1: string().required('Société de facturation: adresse manquante'),
  addressZip: string().required('Société de facturation: code postal manquant'),
  addressTown: string().required('Société de facturation: ville manquante'),
  addressCountrycode: string()
    .length(
      2,
      "Le code pays addressCountryCode de l'adresse de la societé de facturation doit contenir deux caractères",
    )
    .optional(),
});

schemas.pricing = object({
  purchasePricePerDayWithoutTaxes: number().required(`Salaire Brut Annuel TTC / TJ d'achat HT en € (Bloomer)`),
  salePricePerDayWithoutTaxes: number().required(`Commission CDI HT / TJM de vente HT en € (Client)`),
  astreinteCoef: number().required('Prix: AstreinteCoef manquant'),
  startDate: string().optional(),
});

schemas.mission = object({
  bloomer: schemas.bloomer.required('Mission: Bloomer manquant'),
  label: string().required('Mission: Nom de mission manquant'),
  pricing: schemas.pricing.required('Mission: Prix manquant'),
  beginMission: string().required('Mission: Début de mission manquant'),
  endMission: string().required('Mission: Fin de mission manquant'),
  clientSociety: schemas.society.required('Mission: Société manquante'),
  contactOperations: schemas.contact.required('Mission: Contact opérationnel manquant'),
  externalId: string().required(),
  type: string().optional(),
  clientPaydateDays: number().nullable().notRequired(),
  bloomerPaydateDays: number().required('Mission: Délai de réglement Bloomer manquant'),
});

schemas.BPASociety = object({
  name: string().required(),
  siret: string().when('addressCountrycode', (addressCountrycode, schema) => {
    if (!AddressCountryCodeEU?.includes(addressCountrycode)) {
      return schema;
    }
    return yupVATSiret;
  }),
  addressTown: string().required('Société cliente: ville manquante'),
});

schemas.BPAricing = object({
  purchasePricePerDayWithoutTaxes: number().required('Prix: Achat manquant'),
  salePricePerDayWithoutTaxes: number().required('Prix: Vente manquante'),
});

schemas.BPABloomer = object({
  fullname: string().required('Bloomer: Nom complet manquant'),
  email: string().required('Bloomer: Email manquant').email(),
  accountManagerName: string().required('Bloomer: KAM manquant'),
  accountManager: array().of(schemas.KAM.required('Bloomer: KAM manquant')),
});

schemas.BPA = object({
  bloomer: schemas.BPABloomer.required('Mission: Bloomer manquant'),
  label: string().required('Mission: Nom de mission manquant'),
  pricing: schemas.BPAricing.required('Mission: Prix manquant'),
  beginMission: string().required('Mission: Début de mission manquant'),
  clientSociety: schemas.BPASociety.required('Mission: Société manquante'),
  contactOperations: schemas.contact.required('Mission: Contact manquant'),
  bloomerPaydateDays: number().required('Mission: Délai de réglement Bloomer manquant'),
});

schemas.recruitement = object({
  Description: string().required('Description manquante'),
  Type: string().required('Type manquant'),
  BillingDate: string().required('Date de démarrage manquante'),
  TrialPeriodEndDate: string().required("Date de fin de période d'essaie manquante"),
  Customer: array().of(string()),
  SalesPriceWithoutTaxes: number().required(),
  BillingContact: array().of(string()).required('Contact de facturation manquant'),
  KAM: array().of(string().required('Account Manager manquant')),
  PaydateDays: number().optional(),
  externalId: string().required(),
});

schemas.pipefyCardData = object({
  id: number().required(),
  pipe_id: string().required(),
  title: string().required(),
});

schemas.pipefyColumn = object({
  id: number().required(),
  name: string().required(),
});

schemas.pipefyUser = object({
  id: number().required(),
  email: string().required(),
  username: string().required(),
});

schemas.moveDealPipefy = object({
  data: object({
    action: string().test('card.move', 'wrong action type', (value) => value === 'card.move'),
    card: schemas.pipefyCardData.required('missing card data'),
    from: schemas.pipefyColumn.required('missing from data'),
    to: schemas.pipefyColumn.required('missing to data'),
    moved_by: schemas.pipefyUser.required('missing moved_by data'),
  }),
});

schemas.deal = object({
  externalId: string().required(),
  label: string().required('Le deal doit avoir un libellé'),
  jobTitle: string().required('Titre du poste est manquant. Vérifiez sur Hubspot.'),
  beginMission: string().required(
    `L'information "Date de début de la mission / du CDI" de la note 'Informations à valider' n'a pas été saisie.`,
  ),
  endMission: string().required(
    `L'information "Date de fin de la mission / de la période d'essai estimée par le client" de la note 'Informations à valider' n'a pas été saisie.`,
  ),
  type: string().required(`Il manque le Type de job. Vérifie sur Hubspot`),
  context: string(),
  leadOrigin: string().required(`Il manque l'Origine du Lead. Vérifie sur Hubspot`),
  bloomerSource: string().required(`Il manque la source du Bloomer. Vérifie sur la note 'Informations à valider'`),
  externalUrl: string().required(),
  enableCRAUpload: bool().required(
    `La question "Quel sera le système de CRA utilisé ?", de la note 'Informations à valider' n'a pas été repondue.`,
  ),
  bloomerInPortage: bool().required(
    `La question "Le bloomer passe par une boite de portage ?", de la note 'BPA' n'a pas été repondue.`,
  ),
  missionAddress: string().required(`L'information "Adresse de la mission" de la note 'BPA' n'a pas été saisie.`),
  additionnalNotes: string(),

  bloomer: schemas.bloomer
    .shape({ externalId: string().required(`Il manque l'id carte bloomer sur pipefy`) })
    .required('Deal data: Bloomer manquant'),
  clientSociety: schemas.society.required('Il manque les informations de la Société client.'),
  contactOperations: schemas.contact.required('Il manque les informations du contact opérationnelle chez le client.'),
  clientBillingSociety: schemas.billingSociety.required('Il manque les informations de la Société de Facturation.'),
  pricing: schemas.pricing.required('Il manque les informations du prix.'),
  clientBillingContact: schemas.billingContact.required(
    'Il manque les informations du contact de facturation chez le client.',
  ),
  legalContact: schemas.legalContact,
  recruiters: array()
    .of(
      object({
        email: string().required(
          `Vérifie si t'as indiqué les informations le buteur, passeur, capitaine et défenseur, dans la note 'BPA'`,
        ),
      }),
    )
    .required(`Il manque les informations le buteur, passeur, capitaine et défenseur, dans la note 'BPA'.`),
});

schemas.hiresweetDealPayload = schemas.deal.shape({
  bloomer: schemas.bloomer.shape({
    accountManagerName: string(),
    accountManager: array().of(object({ email: string().required('Account Manager: Email manquant') })),
  }),
});

schemas.bloomies = object({
  firstname: string().required('Account Manager: Prénom manquant'),
  lastname: string().required('Account Manager: Nom manquant'),
  email: string().required('Account Manager: Email manquant'),
  externalId: string().required('Account Manager: Id externe manquant'),
  role: string().required('Account Manager: Rôle manquant'),
});

schemas.salesDeal = object({
  id: string().required(),
  name: string().required('Nom de la transaction manquant'),
  stage: string().required(),
  society: schemas.society.required(),
  accountManager: schemas.bloomies.required(),
  jobType: string().required('Type de poste manquant'),
  jobTitle: string().required('Titre du poste manquant'),
  dealType: string().required('Deal type manquant. Il est issu du Type de poste.'),
  duration: number().required().typeError('Durée de la mission manquante'),
  onSiteDaysPerWeek: number().required().typeError('Nombre de jours travaillés manquant'),
  remoteDaysPerWeek: number().required().typeError('Nombre de jours en remote manquant'),
  location: string().required('Lieu de la mission manquant'),
  expectedStartDate: string().required('Date de démarrage attendue manquante'),
  activitySector: string().required("Secteur d'activité manquant"),
  context: string().required('Contexte du projet manquant'),
  details: string().required('Détails de la mission manquant'),
  team: string().required("Infos sur l'équipe manquantes"),
  minYearsXP: number().required().typeError('Seniorité minimale manquante'),
  scoreCard1: string().required('Critère de scorecard 1 manquant'),
  scoreCard2: string().required('Critère de scorecard 2 manquant'),
  scoreCard3: string().required('Critère de scorecard 3 manquant'),
  scoreCard4: string().required('Critère de scorecard 4 manquant'),
  requiredLanguages: string().required('Langues requises manquantes'),
  confidentialNotes: string().nullable(),
  maxClientBudget: number().required().typeError('Budget client max manquant'),
  clientBudgetDetails: string().nullable(),
  maxTalentBudget: number().required().typeError('Budget talent max manquant'),
  outOfBudgetProfil: string().nullable(),
  budgetDecisionMaker: string().nullable(),
  isInternalValidation: bool().nullable(),
  clientResearchTrigger: string().required('Déclencheur manquant'),
  interviewProcess: string().required('Déroulement des entretiens manquant'),
  finalValidationResponsable: string().nullable(),
  minTotalProfilesToInclude: string().required('CP coeur ou nb profil min manquant'),
  searchStatus: string().nullable(),
  competitors: string().required('Concurrents manquants'),
  isExclusivity: bool().required().typeError('Exclusivité manquante'),
  cvDebriefDate: string().required('Date de debrief CV manquante'),
  isResponsiveClient: bool().nullable(),
  isReliableClient: bool().nullable(),
  isDirectLinkWithDecisionMaker: bool().nullable(),
  priority: string().nullable(),
  isBloomerReplacement: bool().required().typeError('Remplacement Bloomer manquant'),
  leadOrigin: string().required().typeError('Origine du lead manquante'),
  isQuickContract: bool().nullable(),
});

schemas.quickSalesDeal = schemas.salesDeal.shape({
  duration: number().nullable(),
  onSiteDaysPerWeek: number().nullable(),
  remoteDaysPerWeek: number().nullable(),
  location: string().nullable(),
  expectedStartDate: string().nullable(),
  activitySector: string().nullable(),
  context: string().nullable(),
  details: string().nullable(),
  team: string().nullable(),
  minYearsXP: number().nullable(),
  scoreCard1: string().nullable(),
  scoreCard2: string().nullable(),
  scoreCard3: string().nullable(),
  scoreCard4: string().nullable(),
  requiredLanguages: string().nullable(),
  maxClientBudget: number().nullable(),
  maxTalentBudget: number().nullable(),
  clientResearchTrigger: string().nullable(),
  interviewProcess: string().nullable(),
  minTotalProfilesToInclude: string().nullable(),
  competitors: string().nullable(),
  isExclusivity: bool().nullable(),
  cvDebriefDate: string().nullable(),
  isBloomerReplacement: bool().nullable(),
  leadOrigin: string().nullable(),
});

schemas.emailTemplateBaseProject = object({
  jobTitle: string().required('Le Titre du poste est obligatoire'),
});

schemas.emailTemplateBaseCandidat = object({
  lastname: string().required('Le nom de famille du candidat est obligatoire'),
  firstname: string().required('Le prénom du candidat est obligatoire'),
  email: string().required("L'email du candidat est obligatoire"),
});

schemas.emailTemplateBaseData = object({
  project: schemas.emailTemplateBaseProject.required(),
  candidat: schemas.emailTemplateBaseCandidat.required(),
});

schemas.positionEmailTemplateData = schemas.emailTemplateBaseData.shape({
  project: schemas.emailTemplateBaseProject.shape({
    dealType: string().required('Le type de job est obligatoire. Vérifie sur Hubspot.'),
    location: string().required('Le lieu de la mission est obligatoire. Vérifie sur Hubspot.'),
    duration: string().required(`La durée de la mission/Période d'essai est obligatoire. Vérifie sur Hubspot.`),
    details: string().required('Les détails de la missions sont obligatoires. Vérifie sur Hubspot.'),
    remoteDaysPerWeek: string().required('Le nombre de jours en remote est obligatoire. Vérifie sur Hubspot.'),
  }),
  clientSocietyName: string().required('Le nom de la société cliente est obligatoire. Vérifie sur Hubspot.'),
  candidateNotes: object({
    possibleStartDate: string().required(
      `La question "Quand peut-il commencer la mission/le job ?" n'a pas de réponse. Vérifie la note Pré Qualifiacation.`,
    ),
    expectedPrice: string().required(
      `La question "Quel est son TJM / Package souhaité ?" n'a pas de réponse. Vérifie la note Pré Qualifiacation.`,
    ),
  }),
});

schemas.clientBPAEmailTemplateData = schemas.emailTemplateBaseData.shape({
  project: schemas.emailTemplateBaseProject.shape({
    onSiteDaysPerWeek: string().required('Le nombre de jours travaillés par semaine est obligatoire'),
  }),
  salePricePerDayWithoutTaxes: string().required('Le prix de vente est obligatoire'),
  beginDate: string().required('La date de début de mission est obligatoire'),
  expectedEndDate: string().required('La date de fin estimée est obligatoire'),
  purchaseOrderDuration: string().required('La durée du bon de commande est obligatoire'),
  bloomerPaydateDays: string().required('Les délais de paiement du bloomer sont obligatoires'),
  accountManagerEmail: string().required("L'email de l'Account Manager responsable est obligatoire"),
});

schemas.bloomerBPAEmailTemplateData = schemas.emailTemplateBaseData.shape({
  project: schemas.emailTemplateBaseProject.shape({
    dealType: string().required('Le type de job est obligatoire'),
    location: string().required('Le lieu de la mission est obligatoire'),
    onSiteDaysPerWeek: string().required('Le nombre de jours travaillés par semaine est obligatoire'),
  }),
  clientSocietyName: string().required('Le nom de la société cliente est obligatoire'),
  purchasePricePerDayWithoutTaxes: string().required("Le prix d'achat est obligatoire"),
  beginDate: string().required('La date de début de mission est obligatoire'),
  expectedEndDate: string().required('La date de fin estimée est obligatoire'),
  purchaseOrderDuration: string().required('La durée du bon de commande est obligatoire'),
  bloomerPaydateDays: string().required('Les délais de paiement du bloomer sont obligatoires'),
  accountManagerEmail: string().required("L'email de l'Account Manager responsable est obligatoire"),
});

export const getSchema = (schemaName: string) => {
  const schema = schemas[schemaName];

  if (schema) return schema;

  throw new Error(`Yup schema not found ${schemaName}`);
};
