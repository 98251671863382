import React, { useEffect, useState } from 'react';

import { Button } from '@bloomays-lib/ui.shared';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { BOOM, BOOM_MUTATION } from '@bloomays-lib/adapter.api-bloomer';
import { BOOM as BOOM_TALENT } from '@bloomays-lib/adapter.api-talent';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { Logger } from '../../services/serviceLogger';
import { SelectField } from '../atoms/SelectField';
import { SelectChangeEvent } from '@mui/material/Select';
import { errorLogger } from '../../helpers/error';

const ErrorJSX = (): JSX.Element => {
  const defaultErrorCode = 'XXXX';
  const [errorCode, setErrorCode] = useState<string>(defaultErrorCode);
  const defaultStatusCode = '200';
  const [statusCode, setStatusCode] = useState<string>(defaultStatusCode);
  const [boom, { loading: loadingLazy, error: errorLazy }] = useLazyQuery(BOOM, { fetchPolicy: 'network-only' });
  const [boomTalent, { loading: loadingLazyTalent, error: errorLazyTalent }] = useLazyQuery(BOOM_TALENT, {
    context: { clientName: 'api.talents' },
    fetchPolicy: 'network-only',
  });
  const [boomMutation, { loading: loadingUpdate, error: errorUpdate }] = useMutation(BOOM_MUTATION, {
    fetchPolicy: 'network-only',
  });
  const logger = Logger('error page');

  useEffect(() => {
    if (!errorLazy) {
      return;
    }
    errorLogger(errorLazy, {
      extraInfos: 'Internal server error, impossible to retrive data about talents !',
      state: {},
    });
  }, [errorLazy]);

  useEffect(() => {
    if (!errorLazyTalent) {
      return;
    }
    errorLogger(errorLazyTalent, {
      extraInfos: 'Internal server error, impossible to retrieve data about talents !',
      state: {},
    });
  }, [errorLazyTalent]);

  useEffect(() => {
    if (!errorUpdate) {
      return;
    }
    errorLogger(errorUpdate, {
      extraInfos: 'Internal server error, impossible to retrive data about talents !',
      state: {},
    });
  }, [errorUpdate]);

  return (
    <div>
      <SingleLineText id="textErrorPage" text="Error Test page" />
      {(loadingLazy || loadingUpdate || loadingLazyTalent) && (
        <div>
          <SingleLineText text="Récupération des données..." />
        </div>
      )}
      <SelectField
        value={statusCode}
        optionValue={[defaultStatusCode, '400', '404', '500', '503']}
        name={'StatusCode'}
        handlechange={function (event: SelectChangeEvent<unknown>, child: React.ReactNode): void {
          setStatusCode(event.target.value as string);
        }}
      />
      <Button
        id="boomQueryButton"
        textButton="Throw a boom API Bloomer query"
        onClick={async (e: Event) => {
          e.preventDefault();
          logger.debug('click', statusCode);
          await boom({ variables: { code: Number(statusCode) } });
        }}
      />
      <br />
      <br />
      <Button
        id="boomMutationButton"
        textButton="Throw a API Bloomer mutation"
        onClick={async (e: Event) => {
          e.preventDefault();
          try {
            await boomMutation();
          } catch (err: any) {
            errorLogger(err, {
              extraInfos: 'Boom mutation',
              moduleName: 'Error',
            });
          }
        }}
      />
      <br />
      <br />
      <SelectField
        value={errorCode}
        optionValue={[defaultErrorCode, 'HXXX', 'PXXX', 'P666', 'P2002']}
        name={'ErrorCode'}
        handlechange={function (event: SelectChangeEvent<unknown>, child: React.ReactNode): void {
          setErrorCode(event.target.value as string);
        }}
      />
      <Button
        id="boomQueryButtonAPITalents"
        textButton="Throw a boom API Talents query"
        onClick={async (e: Event) => {
          e.preventDefault();
          await boomTalent({ variables: { errorId: errorCode } });
        }}
      />
      <br />
      <br />
      <br />
      <Button
        id="boomQueryButtonAPITalents"
        textButton="Throw a Client error"
        onClick={(e: Event) => {
          e.preventDefault();
          throw new Error('Client error');
        }}
      />
    </div>
  );
};

export default WithErrorBoundary(ErrorJSX);
