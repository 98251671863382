/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ActionCRA from './ActionCRA';
import { Button, HrefLink } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';
import RawWarningIcon from '@mui/icons-material/Warning';
import RawUploadFileIcon from '@mui/icons-material/UploadFile';
import RawInsertDriveFileIcon from '@mui/icons-material/AssignmentInd';
import RawVisibilityIcon from '@mui/icons-material/Visibility';
import Delete from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { ActivityDayDetails, ActivityDayType, IActivity, IMission, Role } from '@bloomays-lib/types.shared';
import NPSMission from '../organisms/NPSMission';
import { FetchResult } from '@apollo/client';
import { UploadFileActivity } from '../../types/components';
import { Logger } from '../../services/serviceLogger';
import SummaryDaysWorked from '../organisms/SummaryDaysWorked';
import { calculPerDay } from '../../helpers/CRA';
import { FormCRA } from './FormCRA';
import { convertActivityToEachDaysArray, isBefore } from '@bloomays-lib/utils.shared';
import isEmpty from 'lodash/fp/isEmpty';
import { useAuth } from '../../auth/AuthProvider';

const logger = Logger('ActionBundle');

export interface ActionBundleActivity extends IActivity {
  date: Date;
}

export type ActionBundleProps = {
  CRAEditing: boolean;
  CRACanSendInvite: boolean;
  CRAUploaded: boolean;
  CRASignSent: boolean;
  canUploadInvoices: boolean;
  canSeeInvoices: boolean;
  canDeleteInvoices: boolean;
  editFunc: () => void;
  sendFunc: () => void;
  cancelFunc: () => void;
  responseSign: {
    status: string;
    idRow: string | number | null;
  };
  i: number;
  modalUploadCRA: {
    show: boolean;
    id?: string | null | undefined;
  };
  responseUploadCRA: { status: string };
  uploadCRA: UploadFileActivity;
  deleteInvoice: (activityRecordID: string) => Promise<void>;
  setModalUploadBill: () => void;
  CRAValidated?: boolean | null;
  activity: ActionBundleActivity;
  CRAUrlEmbed: string | null;
  refetchActivities: () => void;
  mission: IMission;
  setResponseSign: React.Dispatch<
    React.SetStateAction<{
      status: string;
      idRow: string | number | null;
    }>
  >;
  upsertFollowUp: (args: {
    selected?: number;
    comment?: string;
    collectDate: Date;
    activityId: string;
  }) => Promise<FetchResult<any> | undefined>;
  stateNPS: { loading: boolean };
  getNPS: any;
};

export const ActionBundle = ({
  stateNPS,
  CRAEditing = true,
  CRACanSendInvite = false,
  CRAUploaded = false,
  CRASignSent = false,
  canUploadInvoices,
  canSeeInvoices,
  editFunc,
  sendFunc,
  cancelFunc,
  responseSign = { status: '', idRow: null },
  i,
  modalUploadCRA = { show: false, id: null },
  responseUploadCRA,
  uploadCRA,
  setModalUploadBill,
  CRAValidated,
  activity,
  CRAUrlEmbed,
  refetchActivities,
  setResponseSign,
  mission,
  upsertFollowUp,
  getNPS,
  deleteInvoice,
  canDeleteInvoices,
}: ActionBundleProps): JSX.Element => {
  const [selected, setSelected] = useState<number | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [nbDaysDetails, setNbDaysDetails] = useState<
    { nbDaysDetails: ActivityDayDetails[]; hoursPerDays: number; month: string } | undefined
  >(undefined);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const { t } = useTranslation('bloomer');
  const auth = useAuth();

  const [modalState, setModalState] = useState<{
    nps: { id: null | number | string; show: boolean };
    craActions: { id: null | string; show: boolean };
    loading: { id: null | number | string; show: boolean };
    errorBillingInfos: { show: boolean; fields: string[] };
  }>({
    nps: { id: null, show: false },
    craActions: { id: null, show: false },
    loading: { id: null, show: false },
    errorBillingInfos: { show: false, fields: [] },
  });

  const handleClose = () => {
    setModalState({ ...modalState, nps: { ...modalState.nps, show: false } });
  };
  const handleCloseAction = () => {
    setModalState({ ...modalState, craActions: { id: null, show: false } });
  };

  const handleUploadInvoice = () => {
    const errorFields = [];

    if (isEmpty(mission.bloomerBillingSociety)) {
      errorFields.push(t('missingBillingSociety'));
    } else {
      const billingSociety = mission.bloomerBillingSociety[0];

      const isAdmin = auth?.user?.roles?.includes(Role.Admin);
      const allowedSocietyStatus = ['SUE', 'SHUE', 'Auto Entrepreneur', 'EI'];
      if (isAdmin || (billingSociety.status && allowedSocietyStatus.includes(billingSociety.status))) {
        return setModalUploadBill();
      }

      if (!billingSociety.KBIS) errorFields.push(t('missingKBIS'));
      if (!billingSociety.URSSAFVigilanceCertificate) errorFields.push(t('missingUrssafCertificate'));
      if (!billingSociety.URSSAFVCExpirationDate) errorFields.push(t('missingUrssafExpirationDate'));
      if (
        billingSociety.URSSAFVCExpirationDate &&
        !isBefore(new Date(), new Date(billingSociety.URSSAFVCExpirationDate))
      )
        errorFields.push(t('expiredUrssafCertificate'));
    }

    if (!isEmpty(errorFields)) {
      setModalState({ ...modalState, errorBillingInfos: { show: true, fields: errorFields } });
    } else {
      setModalUploadBill();
    }
  };

  React.useEffect(() => {
    if (modalUploadCRA.show === false) {
      handleClose();
    }
  }, [modalUploadCRA]);

  const labelCRAButton = CRAEditing ? `${t('validateCRA')}` : `${t('cancelOrEditCRA')}`;

  const getPlaceholder = () => {
    if (selected && selected <= 6) {
      return t('negativePHBloomer', { ns: 'NPSMission' });
    } else {
      return t('placeholder', { ns: 'NPSMission' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        flexFlow: 'row nowrap',
        alignItems: 'center',
      }}
    >
      {!CRAValidated && (
        <div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ActionButton
              id="validateOrCancelCRA"
              loading={modalState.loading.show}
              emoji={<SignCRAIcon />}
              disable={modalState.nps.show || modalState.craActions.show}
              onClick={async () => {
                setTimeout(() => {
                  setModalState({ ...modalState, loading: { id: i, show: true } });
                });
                const NPS = await getNPS({
                  variables: {
                    month: activity.date,
                    type: 'bloomer',
                    activityId: activity.recordId,
                  },
                });
                setModalState({ ...modalState, loading: { id: i, show: false } });
                if (NPS?.data?.getMissionFollowUp.score !== null) {
                  setModalState({ ...modalState, craActions: { id: activity.recordId, show: true } });
                } else {
                  setModalState({
                    ...modalState,
                    nps: { id: i, show: true },
                    craActions: { ...modalState.craActions, id: activity.recordId },
                  });
                }
              }}
              textButton={labelCRAButton}
            />
            {CRAEditing && <LinkButton onClick={editFunc} textButton={t('editCRA')} />}
          </Box>
        </div>
      )}
      <Modal
        open={modalState.nps.show}
        onClose={handleClose}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <NPSMission
          stateNPS={stateNPS}
          onClick={async () => {
            await upsertFollowUp({
              comment,
              selected,
              collectDate: activity.date,
              activityId: activity.recordId!,
            });
            handleClose();
            setModalState({ ...modalState, craActions: { id: activity.recordId, show: true } });
          }}
          setSelected={(e: number) => setSelected(e)}
          setComment={(e: any) => setComment(e.target.value)}
          selected={selected}
          comment={comment}
          texts={{
            title: mission.enableCRAUpload
              ? t('uploadTitle', { ns: 'NPSMission' })
              : t('signTitle', { ns: 'NPSMission' }),
            placeholder: getPlaceholder(),
            button: mission.enableCRAUpload
              ? t('buttonUpload', { ns: 'NPSMission' })
              : t('buttonSign', { ns: 'NPSMission' }),
            details: t('details', { ns: 'NPSMission' }),
            nameLink: 'BloomHelp',
            url: 'https://bit.ly/bloomays-help',
            btnTitle: t('thanksToNote', { ns: 'NPSMission' }),
          }}
        />
      </Modal>
      <Modal
        open={!!nbDaysDetails}
        onClose={() => setNbDaysDetails(undefined)}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          height: '90%',
          border: 'none',
          borderRadius: '20px',
          overflow: 'scroll',
        }}
      >
        {nbDaysDetails && (
          <>
            <SummaryDaysWorked
              month={nbDaysDetails.month}
              daysWorked={calculPerDay(nbDaysDetails.nbDaysDetails, ActivityDayType.nbDaysWorked)}
              daysMarkup={calculPerDay(nbDaysDetails.nbDaysDetails, ActivityDayType.nbDaysMarkupWorked)}
              daysMarkup2={calculPerDay(nbDaysDetails.nbDaysDetails, ActivityDayType.nbDaysMarkup2Worked)}
              daysAstreinte={calculPerDay(nbDaysDetails.nbDaysDetails, ActivityDayType.astreinte)}
            />
            <FormCRA
              eachDays={nbDaysDetails.nbDaysDetails || []}
              readOnly={true}
              onCRAUpdate={() => void 0}
              hoursPerDay={nbDaysDetails.hoursPerDays}
            />
          </>
        )}
      </Modal>
      <Modal
        open={modalState.craActions.show}
        onClose={handleCloseAction}
        additionnalCSS={{
          width: '700px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <ActionCRA
          handleCloseAction={handleCloseAction}
          setResponseSign={setResponseSign}
          refetchActivities={refetchActivities}
          CRAUrlEmbed={CRAUrlEmbed}
          responseSign={responseSign}
          CRASignSent={CRASignSent}
          cancelFunc={cancelFunc}
          CRACanSendInvite={CRACanSendInvite}
          sendFunc={sendFunc}
          CRAUploaded={CRAUploaded}
          modalUploadCRA={modalUploadCRA}
          responseUploadCRA={responseUploadCRA}
          uploadCRA={uploadCRA}
          activity={activity}
          mission={mission}
        />
      </Modal>
      <Modal
        open={modalState.errorBillingInfos.show}
        onClose={() => {
          setModalState({ ...modalState, errorBillingInfos: { show: false, fields: [] } });
        }}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <ErrorBillingInfosTitle>
          <IconBox>
            <WarningIcon />
          </IconBox>
          <h4>{t('errorBillingInfosTitle')}</h4>
        </ErrorBillingInfosTitle>
        <ErrorBillingInfosDescription>
          <p>{t('errorBillingInfosDescription')}</p>
          <ul>
            {modalState.errorBillingInfos.fields &&
              modalState.errorBillingInfos.fields.map((field, index) => <li key={index}>⚠️ {field}</li>)}
          </ul>
          {modalState.errorBillingInfos.fields.includes(t('missingBillingSociety')) ? (
            <HrefLink href={`/society/new/mission/${mission.recordId}`} text={t('createBillingSociety')} />
          ) : (
            <HrefLink
              href={
                !isEmpty(mission.bloomerBillingSociety)
                  ? `/society/${mission.bloomerBillingSociety[0].recordId}/mission/${mission.recordId}`
                  : ''
              }
              text={t('modifyMyBillingInfos')}
            />
          )}
        </ErrorBillingInfosDescription>
      </Modal>
      {!CRAEditing && !CRASignSent && (
        <Box>
          <ContainerInvoices>
            {canUploadInvoices ? (
              <ActionButton emoji={<UploadFileIcon />} onClick={handleUploadInvoice} textButton={t('uploadInvoice')} />
            ) : (
              <>
                {canSeeInvoices && activity.urlInvoicesS3 && (
                  <>
                    <ActionButton
                      emoji={<RawVisibilityIcon />}
                      title={t('seeInvoice')}
                      textButton={t('seeInvoice')}
                      onClick={() => window.open(activity.urlInvoicesS3, '_blank')}
                    />
                    {canDeleteInvoices && activity.urlInvoicesS3 && !activity.payed && (
                      <LinkButton
                        emoji={<Delete />}
                        loading={loadingDelete}
                        title={t('deleteInvoice')}
                        textButton={t('deleteInvoice')}
                        onClick={async () => {
                          setTimeout(() => {
                            setLoadingDelete(true);
                          });
                          await deleteInvoice(activity.recordId).catch((err) => {
                            logger.error(err);
                          });
                          setLoadingDelete(false);
                        }}
                      />
                    )}
                  </>
                )}
                {canSeeInvoices && activity.urlAdditionalInvoicesS3 && (
                  <ActionButton
                    emoji={<RawVisibilityIcon />}
                    title={t('seeAdditionalInvoice')}
                    textButton={t('seeAdditionalInvoice')}
                    onClick={() => window.open(activity.urlAdditionalInvoicesS3, '_blank')}
                  />
                )}
              </>
            )}
            {activity?.urlCraS3 && (
              <LinkButton
                title={t('seeCRA')}
                onClick={() => window.open(activity.urlCraS3, '_blank')}
                textButton={t('seeCRA')}
              />
            )}
            {!activity.urlCraS3 && activity.validated && (
              <LinkButton
                title={t('seeCRA')}
                onClick={() => {
                  const isoDate = new Date(activity.date).toISOString();
                  const eachDays = convertActivityToEachDaysArray(isoDate, activity.nbDaysDetails);
                  setNbDaysDetails({
                    nbDaysDetails: eachDays,
                    hoursPerDays: activity.hoursPerDays,
                    month: isoDate,
                  });
                }}
                textButton={t('seeCRA')}
              />
            )}
          </ContainerInvoices>
        </Box>
      )}
    </Box>
  );
};

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 0,
  border: 'none',
  backgroundColor: theme.palette.paper.white,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.tertiary.darkPink,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginBottom: '2px',
}));

const SignCRAIcon = styled(RawInsertDriveFileIcon)(({ theme }) => ({
  paddingRight: '5px',
  width: '19px !important',
}));

const UploadFileIcon = styled(RawUploadFileIcon)(({ theme }) => ({
  paddingRight: '5px',
  width: '19px !important',
}));

const ContainerInvoices = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

const IconBox = styled('div')(() => ({
  backgroundColor: '#FFECF4',
  width: '40px',
  height: '40px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const WarningIcon = styled(RawWarningIcon)({
  color: '#FE83B6',
  width: 20,
});

const ErrorBillingInfosTitle = styled('h3')(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

const ErrorBillingInfosDescription = styled('div')(() => ({
  textAlign: 'left',
  marginTop: '10px',
  ul: {
    margin: '10px 0',
  },
}));
