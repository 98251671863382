import { AlertMessage, Button, Switch, TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import DatePicker from '../atoms/DatePicker';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { cancel, notify } from '../../helpers/toastify';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CreateMissionAmendmentGQLInput, IMissionCardDetails, IMissionCardPricing } from '@bloomays-lib/types.shared';
import isAfter from 'date-fns/fp/isAfter';
import isBefore from 'date-fns/fp/isBefore';
import startOfToday from 'date-fns/startOfToday';
import { convertDateToStringFormat, getFirstValidPrice, greaterDate } from '@bloomays-lib/utils.shared';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import isEqual from 'lodash/fp/isEqual';
import isNull from 'lodash/fp/isNull';
import isInteger from 'lodash/fp/isInteger';
import { useMutation } from '@apollo/client';
import {
  CREATE_MISSION_AMENDMENT,
  GET_EXTENSION_ASSESSMENT,
  GET_ONE_FULL_MISSION,
} from '@bloomays-lib/adapter.api-bloomer';
import { GraphQLError } from 'graphql';
import writtenNumber from 'written-number';
import add from 'date-fns/fp/add';

export type CreateAmendmentProps = {
  mission: IMissionCardDetails;
  handleClose: () => void;
  setPendingOperation: Dispatch<SetStateAction<boolean>>;
};

export const shouldDisableEndDate = (endDate: Date) => (date: Date) => {
  return isBefore(endDate, date);
};

export const shouldDisableConfirmDate = (pickedDate: Date | null) => (date: Date) => {
  if (!pickedDate) return true;
  if (isBefore(startOfToday(), date)) return true;
  if (isAfter(pickedDate, date)) return true;
  return false;
};

const formatDisplayDate = (date: Date | string) => convertDateToStringFormat(date, 'dd-MM-yyyy').replaceAll('-', '/');

export const CreateAmendment = ({ mission, handleClose, setPendingOperation }: CreateAmendmentProps) => {
  const { t } = useTranslation(['extendShortenMission', 'notify']);
  const endDate = new Date(mission?.endMission as string);

  const [extendState, setExtendState] = useState<boolean>(true);
  const [pricingState, setPricingState] = useState<boolean>(false);

  const [newEndMission, setNewEndMission] = useState<Date | null>(null);
  const [confirmNewEndMission, setConfirmNewEndMission] = useState<Date | null>(null);
  const [firstClause, setFirstClause] = useState<string>(t('defaultFirstClause'));

  const [newPurchasePrice, setNewPurchasePrice] = useState<number | null>(null);
  const [newSalePrice, setNewSalePrice] = useState<number | null>(null);
  const [newPricingDate, setNewPricingDate] = useState<Date | null>(null);
  const [secondClauseForSST, setSecondClauseForSST] = useState<string>(t('defaultSecondClause'));
  const [secondClauseForPDS, setSecondClauseForPDS] = useState<string>(t('defaultSecondClause'));

  const [invalidPrice, setInvalidPrice] = useState<{ purchasePrice: boolean; salePrice: boolean }>({
    purchasePrice: false,
    salePrice: false,
  });

  const [useAmendmentTemplate, setUseAmendmentTemplate] = useState<{
    useTemplateForSST: boolean;
    useTemplateForPDS: boolean;
  }>({ useTemplateForSST: true, useTemplateForPDS: mission.contractType === 'bloomaysContract' });

  const [createMissionAmendment, { loading }] = useMutation(CREATE_MISSION_AMENDMENT, {
    refetchQueries: [
      {
        query: GET_ONE_FULL_MISSION,
        variables: {
          recordId: mission.recordId,
        },
      },
      {
        query: GET_EXTENSION_ASSESSMENT,
        variables: { missionId: mission.recordId },
      },
    ],
  });

  useEffect(() => {
    setPendingOperation(loading);
  }, [loading]);

  const getFirstClause = () => {
    if (!newEndMission || !confirmNewEndMission) return;

    const dayAfterEndMission = add({ days: 1 }, new Date(mission.endMission));

    if (newEndMission.getTime() === confirmNewEndMission.getTime()) {
      setFirstClause(
        t('firstClause', {
          dayAfterCurrentEndMission: formatDisplayDate(dayAfterEndMission),
          newEndMission: formatDisplayDate(newEndMission),
          missionLabel: mission.label,
          bloomerFullName: `${mission.bloomer.firstname} ${mission.bloomer.lastname}`,
        }),
      );
    }
  };

  const getSecondClause = ({
    salePrice,
    purchasePrice,
  }: {
    salePrice: number | null;
    purchasePrice: number | null;
  }) => {
    if (!newPricingDate || !purchasePrice || !salePrice) return;

    const pricingText = (price: number) => {
      return t('secondClause', {
        price,
        priceInLetters: writtenNumber(price, { lang: 'fr' }),
        pricingDate: formatDisplayDate(newPricingDate),
      });
    };

    setSecondClauseForSST(
      Number(purchasePrice) === Number(activePricing.purchasePricePerDayWithoutTaxes)
        ? t('defaultSecondClause')
        : pricingText(purchasePrice),
    );
    setSecondClauseForPDS(
      Number(salePrice) === Number(activePricing.salePricePerDayWithoutTaxes)
        ? t('defaultSecondClause')
        : pricingText(salePrice),
    );
  };

  const handleError = (graphqlErrors: [GraphQLError]) => {
    if (graphqlErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
      const invalidArgs = graphqlErrors?.[0]?.extensions?.invalidArgs as Array<{
        missing: string;
      }>;
      if (invalidArgs) {
        notify(
          'error',
          t('missingFieldError', {
            ns: 'notify',
            missingField: invalidArgs[0].missing as string,
          }),
        );
      } else {
        notify(
          'error',
          t('missingFieldError', {
            ns: 'notify',
            missingField: graphqlErrors?.[0].message,
          }),
        );
      }
    } else {
      notify('error', t('randomUpdateError'));
    }
  };

  const createAmendment = async (useTemplateForSST: boolean, useTemplateForPDS: boolean) => {
    try {
      notify('info', t('updateInProgress', { ns: 'notify' }));
      const input: CreateMissionAmendmentGQLInput = {
        recordId: mission.recordId,
        newEndMission: extendState ? convertDateToStringFormat(newEndMission) : null,
        useTemplateForSST: disableSSTSwitch ? false : useTemplateForSST,
        useTemplateForPDS: disablePDSSwitch ? false : useTemplateForPDS,
        newPurchasePrice: pricingState ? newPurchasePrice : null,
        newSalePrice: pricingState ? newSalePrice : null,
        newPricingDate: pricingState ? convertDateToStringFormat(newPricingDate) : null,
        firstClause: extendState ? firstClause : t('defaultFirstClause'),
        secondClauseForSST: pricingState ? secondClauseForSST : t('defaultSecondClause'),
        secondClauseForPDS: pricingState ? secondClauseForPDS : t('defaultSecondClause'),
      };
      const response = await createMissionAmendment({
        variables: {
          input,
        },
      });
      if (response?.data.createMissionAmendment) {
        cancel();
        notify('success', t('updateSuccess', { ns: 'notify' }));
        handleClose();
      } else {
        cancel();
        handleError(response.errors as [GraphQLError]);
      }
    } catch (e: any) {
      cancel();
      handleError(e.graphQLErrors as [GraphQLError]);
    }
  };

  const contractSSTAmendmentLabel =
    mission.contractType === 'bloomaysContract'
      ? 'generateAutomaticSSTContractAmendmentBloomays'
      : 'generateAutomaticSSTContractAmendmentNotBloomays';

  const invalidPricing = invalidPrice.purchasePrice || invalidPrice.salePrice;

  const uncompletePricing =
    (pricingState && (isNull(newPurchasePrice) || isNull(newSalePrice) || isNull(newPricingDate))) || invalidPricing;
  const uncompleteExtend = extendState && (isNull(newEndMission) || isNull(confirmNewEndMission));

  const prices = mission.prices as IMissionCardPricing[];

  const activePricing = getFirstValidPrice(
    prices,
    greaterDate(new Date(mission?.beginMission as string), new Date()),
  ) as IMissionCardPricing;

  const purchasePriceNoChange = newPurchasePrice === activePricing.purchasePricePerDayWithoutTaxes;
  const salePriceNoChange = newSalePrice === activePricing.salePricePerDayWithoutTaxes;

  const pricingNoChange = purchasePriceNoChange && salePriceNoChange;

  const disableSSTSwitch = !extendState && purchasePriceNoChange;
  const disablePDSSwitch = !extendState && salePriceNoChange;

  const differentExtendDates =
    extendState &&
    !isNull(newEndMission) &&
    !isNull(confirmNewEndMission) &&
    !isEqual(newEndMission, confirmNewEndMission);

  const disabledButton =
    (!pricingState && !extendState) ||
    loading ||
    uncompletePricing ||
    uncompleteExtend ||
    pricingNoChange ||
    differentExtendDates;

  return (
    <CreateAmendmentContainer>
      <TitlePart textTitle={t('missionAmendment')} />
      <SwitchSection title={t('extendTitle')} state={extendState} setState={setExtendState}>
        <SingleLineText text={t('newDateText')} />
        <DatesContainer>
          <DatePicker
            shouldDisableDate={shouldDisableEndDate(endDate)}
            openTo="day"
            views={['day']}
            label={t('pickDate')}
            handleChange={(e: Date | null) => {
              setNewEndMission(e);
            }}
            onAccept={() => {
              getFirstClause();
            }}
            value={newEndMission}
          />
          <DatePicker
            shouldDisableDate={shouldDisableConfirmDate(newEndMission)}
            openTo="day"
            views={['day']}
            label={t('pickConfirmDate')}
            handleChange={(e: Date | null) => {
              setConfirmNewEndMission(e);
            }}
            onAccept={() => {
              getFirstClause();
            }}
            value={confirmNewEndMission}
          />
        </DatesContainer>
        {differentExtendDates && (
          <AlertMessageContainer>
            <AlertMessage title={t('errorExtendTitle')} description={t('errorExtendDescription')} icon="error" />
          </AlertMessageContainer>
        )}
        <ValidationTextField
          maxLength="290"
          type={TypeInput.text}
          multiline={true}
          label={"Texte de l'article 1 - Durée de la mission"}
          value={firstClause}
          onChange={(e) => {
            setFirstClause(e.target.value);
          }}
          size={'small'}
          disabled={false}
          helperText={t('durationHelperText')}
        />
      </SwitchSection>
      <SwitchSection title={t('modifyPricing')} state={pricingState} setState={setPricingState}>
        <SingleLineText text={t('newPricingText')} />
        <PricingInputContainer>
          <ValidationTextField
            type={TypeInput.number}
            label={"Rappel TJM d'achat HT actuel"}
            value={activePricing.purchasePricePerDayWithoutTaxes || ''}
            disabled={true}
          />
          <ValidationTextField
            type={TypeInput.number}
            label={"Nouveau TJM d'achat HT"}
            value={newPurchasePrice || ''}
            onChange={(e: any) => {
              if (isInteger(Number(e.target.value))) {
                setInvalidPrice({ ...invalidPrice, purchasePrice: false });
                setNewPurchasePrice(Number(e.target.value));
                getSecondClause({ purchasePrice: e.target.value, salePrice: newSalePrice });
              } else {
                setInvalidPrice({ ...invalidPrice, purchasePrice: true });
              }
            }}
          />
          <ValidationTextField
            type={TypeInput.number}
            label={'Rappel TJM de vente HT actuel'}
            value={activePricing.salePricePerDayWithoutTaxes || ''}
            disabled={true}
          />
          <ValidationTextField
            type={TypeInput.number}
            label={'Nouveau TJM de vente HT'}
            value={newSalePrice || ''}
            onChange={(e: any) => {
              if (isInteger(Number(e.target.value))) {
                setInvalidPrice({ ...invalidPrice, salePrice: false });
                setNewSalePrice(Number(e.target.value));
                getSecondClause({ salePrice: e.target.value, purchasePrice: newPurchasePrice });
              } else {
                setInvalidPrice({ ...invalidPrice, salePrice: true });
              }
            }}
          />
          <div style={{ margin: '5px 0px' }}>
            <DatePicker
              openTo="day"
              views={['day']}
              label="Effectif le"
              handleChange={(e: Date | null) => {
                setNewPricingDate(e);
              }}
              onAccept={() => {
                getSecondClause({ salePrice: newSalePrice, purchasePrice: newPurchasePrice });
              }}
              value={newPricingDate}
            />
          </div>
        </PricingInputContainer>
        {pricingNoChange && (
          <AlertMessageContainer>
            <AlertMessage title={t('errorPricingTitle')} description={t('errorPricingDescription')} icon="error" />
          </AlertMessageContainer>
        )}
        {invalidPricing && (
          <AlertMessageContainer>
            <AlertMessage title={t('invalidPricingTitle')} description={t('invalidPricingDescription')} icon="error" />
          </AlertMessageContainer>
        )}
        <ValidationTextField
          maxLength="290"
          type={TypeInput.text}
          multiline={true}
          label={"Avenant Bloomer (SST): Texte de l'article 2 - Prix"}
          value={secondClauseForSST}
          onChange={(e) => {
            setSecondClauseForSST(e.target.value);
          }}
          size={'small'}
          disabled={disableSSTSwitch}
        />
        <ValidationTextField
          maxLength="290"
          type={TypeInput.text}
          multiline={true}
          label={"Avenant Client (PDS): Texte de l'article 2 - Prix"}
          value={secondClauseForPDS}
          onChange={(e) => {
            setSecondClauseForPDS(e.target.value);
          }}
          size={'small'}
          disabled={disablePDSSwitch}
          helperText={t('pricingHelperText')}
        />
      </SwitchSection>
      <Section>
        {!pricingState && !extendState ? (
          <AlertMessageContainer>
            <AlertMessage title={t('missingChoiceTitle')} description={t('missingChoiceDescription')} icon="info" />
          </AlertMessageContainer>
        ) : (
          <>
            <h3 style={{ fontFamily: 'Poppins Bold' }}>{t('automaticAmendment')}</h3>
            <SwitchContainer>
              <Switch
                id="useTemplateForSST"
                disabled={disableSSTSwitch}
                checked={disableSSTSwitch ? false : useAmendmentTemplate.useTemplateForSST}
                onSwitch={(e, checked) => {
                  setUseAmendmentTemplate((prevState) => ({
                    ...prevState,
                    useTemplateForSST: checked,
                  }));
                }}
                label={t(contractSSTAmendmentLabel)}
              />
              {mission.contractType === 'bloomaysContract' && (
                <Switch
                  id="useTemplateForPDS"
                  disabled={disablePDSSwitch}
                  checked={disablePDSSwitch ? false : useAmendmentTemplate.useTemplateForPDS}
                  onSwitch={(e, checked) => {
                    setUseAmendmentTemplate((prevState) => ({
                      ...prevState,
                      useTemplateForPDS: checked,
                    }));
                  }}
                  label={t('generateAutomaticPDSContractAmendmentBloomays')}
                />
              )}
            </SwitchContainer>
          </>
        )}
      </Section>
      <ButtonsContainer>
        <Button textButton={t('cancel', { ns: 'random' })} onClick={() => handleClose()} />
        <Button
          disable={disabledButton}
          textButton={t('save', { ns: 'random' })}
          onClick={async () => {
            await createAmendment(useAmendmentTemplate.useTemplateForSST, useAmendmentTemplate.useTemplateForPDS);
          }}
        />
      </ButtonsContainer>
    </CreateAmendmentContainer>
  );
};

type SwitchSectionProps = {
  title: string;
  state: boolean;
  setState: (value: boolean) => void;
  children: React.ReactNode;
};

const SwitchSection = ({ title, state, setState, children }: SwitchSectionProps) => {
  return (
    <Section>
      <SwitchAndTitle>
        <Switch
          checked={state}
          onSwitch={() => {
            setState(!state);
          }}
        />
        <h3 style={{ fontFamily: 'Poppins Bold' }}>{title}</h3>
      </SwitchAndTitle>
      {state && children}
    </Section>
  );
};

const CreateAmendmentContainer = styled('div')(() => ({
  padding: '30px',
}));

const Section = styled('div')(() => ({
  padding: '10px 0',
  textAlign: 'left',
}));

const SwitchAndTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

const SwitchContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
  margin: '20px 0',
}));

const PricingInputContainer = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  margin: '15px 0 5px',
  '> *': {
    flex: '1',
    height: '100%',
  },
}));

const AlertMessageContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary[600],
  padding: '15px',
  borderRadius: '20px',
  marginBottom: '25px',
  color: '#5966BF',
  display: 'flex',
}));

const DatesContainer = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  margin: '10px 0 20px',
}));
